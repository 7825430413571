<template>
  <div class="content">
    <!-- <crumbs :show="true"></crumbs> -->

    <div class="header">
      <!-- <zhediebtn /> -->
      <!-- <div class="flexSt">
        <div class="schoolname">{{ school_name || "该账号暂无学校名" }}</div>
      </div> -->
      <el-breadcrumb class="crumb flexSt" separator="/">
        <el-breadcrumb-item :to="{ path: '/index' }">
          <div class="ad">
            <i class="iconfont icon-antOutline-home"></i>首页
          </div>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <div class="flexSe">
        <div class="xzdata">
          <span
            style="
              border: 1px solid #112bb4;
              margin-right: 15px;
              color: #fff;
              font-size: 400 !important;
              background: #112bb4;
              padding: 2px 2px 2px 2px;
              border-radius: 50%;
            "
            v-if="
              this.duibidate != this.duibiriqi &&
              this.qeuryweek.start_time == this.duibiriqi
            "
            @click="dingweidangqinriqi"
            >今</span
          >
          <span v-show="weeks">第{{ weeks }}周</span>
          {{ datatablelist[actx].months }}月 {{ year }}年
        </div>
        <div class="qhbtnbox">
          <div class="" @click="changeCount(1), getWeek(count)">
            <img class="left" src="../../assets//home/路径 46506.png" alt="" />
          </div>
          <div class="" @click="changeCount(-1), getWeek(count)">
            <img class="left" src="../../assets//home/路径 46505.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="riqibox">
      <div
        class="riqi"
        :class="{ act: act == j }"
        v-for="(i, j) in datatablelist"
        :key="j"
        @click="changeriqi(i, j)">
        <div class="zhouqi">{{ i.datas }}</div>
        <div class="rili">{{ i.calendar }}</div>
        <div v-if="act == j" class="xianshi"></div>
      </div>
    </div>
    <div class="xuanze">
      <div class="kecheng">
        <div class="">
          <span class="cks">课程</span>
          <span v-if="rqact" class="kcrq"
            >{{ datatablelist[actx].months }}月{{
              datatablelist[0].calendar
            }}日--{{ datatablelist[6].months }}月{{
              datatablelist[6].calendar
            }}日</span
          >
          <span v-else class="kcrq"
            >{{ datatablelist[actx].months }}月{{
              datatablelist[act].calendar
            }}日</span
          >
        </div>
        <div class="yinwen">More+</div>
      </div>
      <div class="zuoye">
        <div class="">
          <span class="cks">作业</span>
          <span v-if="rqact" class="kcrq"
            >{{ datatablelist[actx].months }}月{{
              datatablelist[0].calendar
            }}日--{{ datatablelist[6].months }}月{{
              datatablelist[6].calendar
            }}日</span
          >
          <span v-else class="kcrq"
            >{{ datatablelist[actx].months }}月{{
              datatablelist[act].calendar
            }}日</span
          >
        </div>
        <div style="display: flex">
          <div class="banji">
            <el-dropdown>
              <span class="el-dropdown-link">
                全部班级<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown"> </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="yinwen">More+</div>
        </div>
      </div>
    </div>
    <div class="neirongqi">
      <div class="leftbox">
        <div class="kejieshu" v-if="courselist.length > 0">
          <div class="kechengsta">
            <div class="kjs" v-for="(item, index) in courselist" :key="index">
              <div class="zhuangtaibox">
                <span class="jishu">第{{ item.class_index }}节</span>
                <div class="zhuangtai" v-if="item.course_status == 2">
                  已结束
                </div>
                <div class="zhuangtai1" v-if="item.course_status == 1">
                  上课中
                </div>
                <div class="zhuangtai2" v-if="item.course_status == 0">
                  待开课
                </div>
              </div>
              <p></p>
            </div>
          </div>
          <div class="xianbox">
            <div
              class="kjs"
              v-for="(item, index) in courselist.length - 1"
              :key="index">
              <div>
                <div class="xian"></div>
              </div>
            </div>
          </div>
          <div>
            <div class="kjs1" v-for="(item, index) in courselist" :key="index">
              <div>
                <div class="physical">
                  <div
                    class="classjob"
                    :class="{ kcstaus1: item.course_status == 1 }">
                    {{ item.class_name || "未设置班级" }}
                  </div>
                  <div style="display: flex">
                    <div>
                      <div style="display: flex">
                        <div
                          class="lables"
                          :class="{ kcstaus: item.course_status == 1 }"
                          v-for="(j, index) in item.project_name"
                          :key="index">
                          {{ j || "暂无标签" }}
                        </div>
                      </div>
                      <div class="canshu">
                        <img
                          class="imgcs"
                          v-if="item.course_status != 1"
                          src="../../assets/home/组 7449.png"
                          alt="" />
                        <img
                          class="imgcs"
                          v-if="item.course_status == 1"
                          src="../../assets/home/组 7452.png"
                          alt="" />
                        <span
                          v-if="item.course_type == 1"
                          class="wenzisize"
                          :class="{
                            kcstaus1:
                              item.course_type == 1 && item.course_status == 1,
                          }"
                          >常规体育课</span
                        >
                        <span
                          v-else
                          class="wenzisize"
                          :class="{
                            kcstaus1:
                              item.course_type == 2 && item.course_status == 1,
                          }"
                          >大课间</span
                        >
                        <img
                          class="imgcs1"
                          v-if="item.course_status != 1"
                          src="../../assets/home/路径 46865.png"
                          alt="" />
                        <img
                          class="imgcs1"
                          v-if="item.course_status == 1"
                          src="../../assets/home/路径 46866.png"
                          alt="" />
                        <span
                          class="wenzisize"
                          :class="{ kcstaus1: item.course_status == 1 }"
                          >{{ item.class_start_time }}-{{
                            item.class_end_time
                          }}</span
                        >
                      </div>
                      <!-- <div class="flexSt">
                        <el-button
                          @click="
                            handleToGo(
                              'http://sjj.sishengsports.cn/skipping?school_id=' +
                                schoolID +
                                '&class_id=' +
                                item.class_id +
                                '&time=1&competitionTypes=1'
                            )
                          "
                          plain
                          >跳绳PK赛</el-button
                        >
                        <el-button
                          @click="
                            handleToGo(
                              'http://sjj.sishengsports.cn/trampoline?school_id=' +
                                schoolID +
                                '&class_id=' +
                                item.class_id +
                                '&time=20&count=30&competitionTypes=2&isRecording=1'
                            )
                          "
                          plain
                          >蹦床PK赛</el-button
                        >
                      </div> -->
                    </div>
                    <div class="jiantou">
                      <img
                        @click="handleToclassroom(item)"
                        class="imgcs2"
                        v-if="item.course_status != 1"
                        src="../../assets//home/组 7453.png"
                        alt="" />
                      <img
                        @click="handleToclassroom(item)"
                        class="imgcs2"
                        v-if="item.course_status == 1"
                        src="../../assets//home/组 7456.png"
                        alt="" />
                    </div>
                  </div>
                  <div class="xian1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="zanwushuju">
          <img
            class="imgsbox"
            src="../../assets/home/zanwushuju.png"
            alt="" /><br />
          <span class="zanwushu">无数据</span>
        </div>
      </div>
      <div class="rightbox">
        <div class="schedule" v-for="(item, index) in tujilist" :key="index">
          <div class="ztname">{{ item.name }}</div>
          <div class="ztjindu" v-if="item.shows > 0">{{ item.percentage }}</div>
          <div class="ztjindu1" v-else-if="item.shows == 0">
            <img
              class="imgsbox1"
              src="../../assets/home/zanwu1.png"
              alt="" /><br />
            无数据
          </div>
          <div ref="chart" :id="item.echartid" class="echartswz"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import * as echarts from "echarts";
import zhediebtn from "../../components/zhediebtn.vue";
import { courseweek } from "../../api/classroon/index";
import { homework } from "../../api/homepage/index";
import { getSchoolID, getUser } from "@/utils/auth";
export default {
  data() {
    return {
      schoolID: getSchoolID(),
      isCreated: false,
      duibiriqi: "",
      duibidate: "",
      year: "",
      monday: "",
      months: 0,
      dtday: "",
      days: "",
      count: 0,
      kcstaus: 2,
      act: 6,
      actx: 6,
      option: {
        xAxis: {
          type: "category",
          data: ["2301班", "2302班", "2303班", "2304班", "2305班"],
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
          },
        },
        series: [
          {
            data: [50, 20, 70, 44, 35, 47, 90],
            type: "line",
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
      },
      optionwcd: {
        xAxis: {
          type: "category",
          data: [],
          max: function (value) {
            if (value.max < 5) {
              value.max = 5;
            } else {
              value.max = value.max;
            }
            return value.max;
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [],
            type: "bar",
          },
        ],
      },

      optionpjxl: {
        // title: {
        //         text: '暂无数据',
        //         x: 'center',
        //         y: 'center',
        //         textStyle: {
        //           fontSize: 14,
        //           fontWeight: 'normal',
        //         }
        //       },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
          },
        },
        series: [
          {
            data: [],
            type: "line",
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
      },
      optionnlxh: {
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
          },
        },
        series: [
          {
            data: [],
            type: "line",
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
      },
      tujilist: [
        {
          name: "完成度",
          echartid: "wanchengdu",
          percentage: "80%平均",
        },
        {
          name: "平均时长",
          echartid: "pjshichang",
          percentage: `3'28''`,
        },
        {
          name: "平均心率",
          echartid: "pjxinlv",
          percentage: "0",
        },
        {
          name: "平均能量消耗",
          echartid: "nengliangxh",
          percentage: "300千卡",
        },
      ],
      datatablelist: [
        {
          mondays: "",
          datas: "周一",
          calendar: "",
        },
        {
          mondays: "",
          datas: "周二",
          calendar: 21,
        },
        {
          mondays: "",
          datas: "周三",
          calendar: 22,
        },
        {
          mondays: "",
          datas: "周四",
          calendar: 23,
        },
        {
          mondays: "",
          datas: "周五",
          calendar: 24,
        },
        {
          mondays: "",
          datas: "周六",
          calendar: 25,
        },
        {
          mondays: "",
          datas: "周日",
          calendar: 27,
        },
      ],
      qeuryweek: {
        start_time: "",
        end_time: "",
      },
      weeks: 0,
      rqact: false,
      courselist: [],
      school_name: "",
    };
  },
  methods: {
    handleToGo(url) {
      window.open(url);
    },
    handleToclassroom(item) {
      let start_time =
        this.year +
        "-" +
        this.datatablelist[this.actx].months +
        "-" +
        this.datatablelist[0].calendar;
      let end_time =
        this.year +
        "-" +
        this.datatablelist[this.actx].months +
        "-" +
        this.datatablelist[6].calendar;
      console.log(item, "=item.course_status ");
      if (item.course_status != 2) {
        this.$router.push(
          "/classroom?status=0" +
            "&start_time=" +
            start_time +
            "&end_time=" +
            end_time +
            "&count=" +
            this.count +
            "&is_prepared=" +
            item.is_prepared +
            "&item=" +
            JSON.stringify(item)
        );
      } else {
        if (item.course_type == 1) {
          this.$router.push("/classreport?val=" + JSON.stringify(item));
        }
      }
    },

    //获取第几周和课堂数据
    async getweeks() {
      await courseweek(this.qeuryweek).then((res) => {
        this.isCreated = false;
        this.weeks = res.data.week;
        this.courselist = res.data.data;
        console.log("第几周和课堂数据", res);
      });
    },
    //获取作业统计
    gethomework() {
      homework(this.qeuryweek).then((res) => {
        // this.tujilist[0].percentage = res.data.progress_avg + "%平均";
        // this.tujilist[1].percentage = res.data.time_avg;
        // this.tujilist[2].percentage = res.data.heart_rate_avg + "次/分";
        // this.tujilist[3].percentage = res.data.kcalAvg + "千卡";

        this.tujilist[0].percentage = 95 + "%平均";
        this.tujilist[1].percentage = 45 + "分钟";
        this.tujilist[2].percentage = 135 + "次/分";
        this.tujilist[3].percentage = 210 + "千卡";

        // this.tujilist[0].shows = res.data.progress_data.length;
        // this.tujilist[1].shows = res.data.timeKcalData.length;
        // this.tujilist[2].shows = res.data.heart_rate_data.length;
        // this.tujilist[3].shows = res.data.timeKcalData.length;

        this.tujilist[0].shows = 5;
        this.tujilist[1].shows = 5;
        this.tujilist[2].shows = 5;
        this.tujilist[3].shows = 5;

        // console.log(res.data.progress_data.length);
        //完成度
        // this.optionwcd.xAxis.data = res.data.progress_data.map((item) => {
        //   return item.class_name;
        // });

        this.optionwcd.xAxis.data = [
          "2301班",
          "2302班",
          "2303班",
          "2304班",
          "2305班",
        ];
        // this.optionwcd.series[0].data = res.data.progress_data.map((item) => {
        //   return item.progress;
        // });

        this.optionwcd.series[0].data = [95, 93, 94, 85, 92];
        if (this.tujilist[0].shows > 0) {
          this.$nextTick(() => {
            this.wcdinit();
          });
        } else {
          this.$nextTick(() => {
            var chartDom = document.getElementById("wanchengdu");
            chartDom.innerHTML = ``;
            chartDom.removeAttribute("_echarts_instance_");
          });
        }
        //平均时长
        this.option.xAxis.data = res.data.timeKcalData.map((item) => {
          return item.class_name;
        });
        this.option.xAxis.data = [
          "2301班",
          "2302班",
          "2303班",
          "2304班",
          "2305班",
        ];

        // this.option.series[0].data = res.data.timeKcalData.map((item) => {
        //   return item.spend_time;
        // });
        this.option.series[0].data = [30, 40, 35, 45, 45, 45];
        if (this.tujilist[1].shows > 0) {
          this.$nextTick(() => {
            this.init();
          });
        } else {
          this.$nextTick(() => {
            var chartDom = document.getElementById("pjshichang");
            chartDom.innerHTML = "";
            chartDom.removeAttribute("_echarts_instance_");
          });
        }
        //平均能量消耗
        // this.optionnlxh.xAxis.data = res.data.timeKcalData.map((item) => {
        //   return item.class_name;
        // });

        this.optionnlxh.xAxis.data = [
          "2301班",
          "2302班",
          "2303班",
          "2304班",
          "2305班",
        ];
        // this.optionnlxh.series[0].data = res.data.timeKcalData.map((item) => {
        //   return item.kcal;
        // });

        this.optionnlxh.series[0].data = [220, 220, 200, 210, 230, 225];
        if (this.tujilist[2].shows > 0) {
          this.$nextTick(() => {
            this.lnxhinit();
          });
        } else {
          this.$nextTick(() => {
            var chartDom = document.getElementById("nengliangxh");
            chartDom.innerHTML = "";
            chartDom.removeAttribute("_echarts_instance_");
          });
        }

        //平均心率

        this.optionpjxl.xAxis.data = [
          "2301班",
          "2302班",
          "2303班",
          "2304班",
          "2305班",
        ];
        // this.optionnlxh.series[0].data = res.data.timeKcalData.map((item) => {
        //   return item.kcal;
        // });

        this.optionpjxl.series[0].data = [130, 135, 135, 145, 145, 125];
        if (this.tujilist[3].shows > 0) {
          this.$nextTick(() => {
            this.pjxlinit();
          });
        } else {
          this.$nextTick(() => {
            var chartDom = document.getElementById("pjxinlv");
            chartDom.innerHTML = "";
            chartDom.removeAttribute("_echarts_instance_");
          });
        }
        // console.log('res数据',res.data);
        // console.log('this.option.xAxis.data',this.optionwcd.xAxis.data,this.optionwcd.series[0].data);
      });
    },
    gb() {
      alert("xxx");
    },
    changeriqi(i, j) {
      this.rqact = false;
      // console.log(i, 'zzzzzzzz');
      console.log(this.act, "=== this.act");
      this.dtday = i.calendar;
      this.xzday = parseInt(this.dtday) + 1;
      this.months = parseInt(i.mondays);
      this.act = j;
      this.actx = j;
      this.duibiriqi = this.year + "-" + i.mondays + "-" + this.dtday;
      this.qeuryweek.start_time =
        this.year + "-" + i.mondays + "-" + this.dtday;
      this.qeuryweek.end_time =
        this.year + "-" + i.mondays + "-" + this.dtday + " " + "23:59:59";
      for (let u = 0; u < 2; u++) {
        this.getweeks();
        this.gethomework();
      }
    },
    //改变选择器次数
    changeCount(i) {
      this.count += i;
      this.rqact = true;
      this.act = -10;
      return this.count;
    },
    dingweidangqinriqi() {
      location.reload();
    },
    getWeek(i) {
      let now = new Date();
      let dqrq = now.getDate();
      this.dtday = dqrq;

      let weekOfDay = parseInt(moment().format("E")); //计算今天是这周第几天
      this.actx = weekOfDay - 1;
      for (let index = 0; index < 7; index++) {
        let last_monday = moment()
          .subtract(weekOfDay + 7 * i - (index + 1), "days")
          .format("YYYY-MM-DD");
        this.datatablelist[index].calendar = last_monday.slice(8, 10);
        this.datatablelist[index].mondays = last_monday.slice(5, 7);
        this.datatablelist[index].months = parseInt(last_monday.slice(5, 7));
        this.datatablelist[index].year = last_monday.slice(0, 4);
      }
      //周一日期
      let last_monday = moment()
        .subtract(weekOfDay + 7 * i - 1, "days")
        .format("YYYY-MM-DD");

      this.monday = last_monday.slice(5, 7);
      console.log(last_monday, "last_monday");
      this.months = parseInt(this.monday);
      console.log(this.months, "类型判断");
      this.year = last_monday.slice(0, 4);
      this.xxxxxx = this.dtday + 1;
      //     for (let index = 0; index < this.datatablelist.length-1; index++) {
      //       if(this.datatablelist[index].calendar==31){
      //         this.datatablelist[index].calendar = 31
      //         this.datatablelist[index].calendar = 0
      //       }else{
      //         this.datatablelist[index].calendar = parseInt(this.datatablelist[0].calendar)+index

      //       }
      //    }
      //  console.log( this.datatablelist.calendar);
      let last_sunday = moment()
        .subtract(weekOfDay + 7 * (i - 1), "days")
        .format("YYYY-MM-DD"); //周日日期
      this.qeuryweek.start_time = last_monday;
      this.qeuryweek.end_time = last_sunday;
      this.datatablelist[6].calendar = last_sunday.slice(8, 10);
      //调用api获取当前是第几周

      if (!this.isCreated) {
        console.log(i, "=i", this.act);
        this.getweeks();
      }

      //获取作业统计数据
      // this.gethomework()
    },
    init() {
      var chartDom = document.getElementById("pjshichang");
      var myChart = echarts.init(chartDom);
      myChart.setOption(this.option);
    },
    wcdinit() {
      var chartDom = document.getElementById("wanchengdu");
      var myChart = echarts.init(chartDom);
      myChart.setOption(this.optionwcd);
    },
    pjxlinit() {
      var chartDom = document.getElementById("pjxinlv");
      var myChart = echarts.init(chartDom);
      myChart.setOption(this.optionpjxl);
    },
    lnxhinit() {
      var chartDom = document.getElementById("nengliangxh");
      var myChart = echarts.init(chartDom);
      myChart.setOption(this.optionnlxh);
    },
  },
  mounted() {},
  components: {
    zhediebtn,
  },
  created() {
    let weekOfDay = parseInt(moment().format("E")); //计算今天是这周第几天
    this.act = weekOfDay - 1;
    this.actx = weekOfDay - 1;
    this.isCreated = true;
    this.getWeek(0);

    this.$nextTick(() => {
      (this.duibidate =
        this.datatablelist[this.act].year +
        "-" +
        this.datatablelist[this.act].mondays +
        "-" +
        this.datatablelist[this.act].calendar),
        (this.qeuryweek.start_time =
          this.datatablelist[this.act].year +
          "-" +
          this.datatablelist[this.act].mondays +
          "-" +
          this.datatablelist[this.act].calendar),
        (this.qeuryweek.end_time =
          this.datatablelist[this.act].year +
          "-" +
          this.datatablelist[this.act].mondays +
          "-" +
          this.datatablelist[this.act].calendar +
          " " +
          "23:59:59");
      console.log(this.qeuryweek, "当前");

      this.getweeks();
      this.gethomework();
    });
    this.user = JSON.parse(localStorage.getItem("user"));
    this.school_name = this.user.school_name;
  },
};
</script>

<style lang="less" scoped>
/deep/.el-breadcrumb__inner {
  color: #4e5969;
}
/deep/ .el-breadcrumb__item:last-child {
  a {
    color: #101010;
  }
}
/deep/.el-breadcrumb__inner {
  a {
    font-weight: normal;
  }
}
.crumb {
  // line-height: 75px;
  font-size: 18px;
  margin-left: 43px;

  // background-color: pink;
}
// .ad {
//   color: #112bb4;
//   font-weight: bold;
//   i {
//     font-size: 28px;
//   }
//   // color: #101010 !important;
// }

.content {
  width: 100%;
  height: 100%;

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 30px;
    align-items: center;
    padding-right: 36px;
    box-sizing: border-box;
    .schoolname {
      width: 364px;
      height: 37px;
      font-size: 28px;
      color: #112bb4;
      font-weight: bold;
      margin-left: 43px;
    }

    .xzdata {
      margin-left: 842px;
      font-weight: bold;
      color: #112bb4;
      font-size: 28px;
    }

    .qhbtnbox {
      width: 43px;
      margin-left: 42px;
      display: flex;
      padding-top: 9px;
      justify-content: space-between;

      .left {
        width: 11px;
        height: 19px;
      }
    }
  }

  .riqibox {
    display: flex;
    justify-content: space-between;
    margin-top: 29px;
    padding-left: 47px;
    padding-right: 36px;
    height: 111px;

    .riqi {
      width: 190px;
      background: #ffffff;
      text-align: center;
      border-radius: 10px 10px 10px 10px;
      box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.05);

      .zhouqi {
        font-size: 22px;
        color: #112bb4;
        padding-top: 16px;
      }

      .rili {
        font-size: 28px;
        color: #112bb4;
        padding-top: 14px;
      }
    }

    .act {
      border: 1px solid #516cf7;
    }

    .xianshi {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 10px;
      background-color: #516cf7;
      margin-top: 13px;
    }
  }

  .xuanze {
    display: flex;
    height: 37px;
    margin-top: 31px;

    .kecheng {
      width: 730px;
      margin-left: 47px;
      display: flex;
      justify-content: space-between;
    }

    .cks {
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-size: 28px;
      font-weight: bold;
      color: #112bb4;
    }

    .kcrq {
      color: #112bb4;
      font-size: 18px;
      margin-left: 23px;
    }

    .yinwen {
      color: #112bb4;
      font-size: 18px;
      margin-top: 12px;
    }

    .zuoye {
      width: 900px;
      margin-left: 35px;
      display: flex;
      justify-content: space-between;

      .banji {
        margin-top: 12px;
        margin-right: 41px;
      }
    }
  }

  .neirongqi {
    display: flex;
    height: 744px;
    margin-top: 31px;

    .leftbox {
      width: 730px;
      height: 730px;
      margin-left: 47px;
      background: #ffffff;
      overflow-y: scroll;
      display: flex;
      border-radius: 12px 12px 12px 12px;

      .kejieshu {
        display: flex;
        .kechengsta {
          width: 120px;
        }
        .xianbox {
          width: 1px;
        }
      }

      .zanwushuju {
        text-align: center;
        .imgsbox {
          width: 100%;
          margin-left: 110px;
          margin-top: 150px;
        }
        .zanwushu {
          margin-left: 190px;
          font-size: 22px;
          color: #888888;
        }
      }

      .physical {
        width: 590px;
        height: 240px;
        margin-left: 19px;
        padding-top: 5px;

        .imgcs {
          width: 19.5px;
          height: 19.5px;
        }

        .imgcs1 {
          width: 19.5px;
          height: 19.5px;
          margin-left: 29px;
        }

        .imgcs2 {
          width: 30px;
          height: 30px;
          cursor: pointer;
        }

        .wenzisize {
          font-size: 16px;
          margin-left: 11px;
        }

        .classjob {
          font-size: 22px;
          color: #101010;
        }

        .lables {
          width: fit-content;
          width: -moz-fit-content;
          width: -webkit-fit-content;
          margin-top: 13px;
          margin-right: 13px;
          padding: 0px 11px 0px 11px;
          height: 28px;
          font-size: 16px;
          line-height: 28px;
          border-radius: 3px 3px 3px 3px;
          opacity: 1;
          border: 1px solid #666666;
        }

        .kcstaus {
          border: 1px solid #112bb4;
          color: #112bb4;
        }
      }

      .canshu {
        display: flex;
        // margin-top: 25px;
        margin: 25px 0;
      }

      .jiantou {
        position: relative;
        top: 0px;
        left: 242px;
      }

      .xian1 {
        height: 1px;
        width: 100%;
        background: #ededed;
        margin-top: 28px;
      }

      .kcstaus1 {
        color: #112bb4 !important;
      }
      .kjs1 {
        height: 203px;
        padding: 33px 0px 0px 0px;
        display: flex;
      }
      .kjs {
        height: 203px;
        padding: 33px 0px 0px 22px;
        display: flex;
        .zhuangtaibox {
          padding-right: 20px;
        }
        .xian {
          width: 1px;
          height: 228px;
          background: #a4a1b5;
          position: relative;
          bottom: 18px;
          right: 37px;
          top: 25px;
        }

        p::before {
          content: "";
          display: inline-block;
          width: 15px;
          height: 15px;
          border-radius: 10px;
          background-color: #a4a1b5;
        }

        .zhuangtai {
          font-size: 16px;
          color: #444444;
          margin: 10px 0px 0px 2px;
        }

        .zhuangtai1 {
          font-size: 16px;
          color: #00ce39;
          margin: 10px 0px 0px 2px;
        }

        .zhuangtai2 {
          font-size: 16px;
          color: #f99b19;
          margin: 10px 0px 0px 2px;
        }

        .jishu {
          font-size: 22px;
          color: #101010;
        }
      }
    }

    .rightbox {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 900px;
      margin-left: 35px;

      .schedule {
        background: #ffffff;
        width: 440px;
        height: 358px;
        border-radius: 12px 12px 12px 12px;

        .echartswz {
          width: 100%;
          height: 85%;
          position: relative;
          bottom: 30px;
          left: 18px;
          text-align: center;
        }

        .ztname {
          color: #112bb4;
          font-size: 22px;
          padding: 18px 0px 0px 28px;
        }

        .ztjindu {
          color: #101010;
          font-size: 28px;
          padding: 10px 0px 0px 36px;
        }
        .ztjindu1 {
          font-size: 22px;
          color: #888888;
          padding: 30px 0px 0px 36px;
          text-align: center;
          .imgsbox1 {
          }
        }
      }
    }
  }
}

/deep/ .el-dropdown {
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #112bb4;
}
</style>
